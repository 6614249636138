<template>
  <a-form layout="vertical" :model="roleName" r name="clone_task">
    <div class="row">
      <div class="col-sm-12">
        <a-form-item label="Select Organization">
          <a-select
            id="select-organization-filter"
            v-model:value="selectedOrganization"
            style="width: 100%"
            show-search
            placeholder="Select Organization"
            :options="organizationOptions"
            :filter-option="filterOption"
          />
        </a-form-item>

        <a-col>
          <a-form-item label="Task Name" name="taskName">
            <a-input v-model:value="taskName" placeholder="Enter Task Name" />
          </a-form-item>
        </a-col>
      </div>
    </div>
    <div class="buttons-w d-flex justify-content-end">
      <a-button
        type="primary"
        :loading="isCloningTask"
        @click="handleCloneTask"
      >
        Submit
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import spaceMixin from 'src/mixins/handleSpace';
import TaskServices from 'src/services/tasks';
import { actions, queues } from 'src/config/long-running-task-config';
import SQSServices from 'src/services/sqs';
export default {
  name: 'CloneTask',
  mixins: [spaceMixin],
  inject: ['toast'],
  props: {
    taskId: {
      type: Number,
    },
    organizationOptions: {
      type: Array,
    },
  },
  emits: ['closeModal'],
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      taskName: '',
      selectedOrganization: ref('retrocausal-labeling'),
      organizationList: [],
      isCloningTask: false,
    };
  },
  methods: {
    async handleCloneTask() {
      if (this.taskName == '') {
        this.toast.error('Please enter task name');
        return;
      }
      this.isCloningTask = true;
      const payload = this.getPayload();
      const isTaskExist = await this.getIsTaskExist();
      console.log({ isTaskExist });

      if (isTaskExist) {
        this.isCloningTask = false;
        return;
      }

      const [error, data] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log({ error });
        return;
      }
      this.toast.success(data.Status);

      this.isCloningTask = false;
      this.$emit('closeModal');
    },

    getPayload() {
      return {
        queueName: queues.longRunningTask,
        sqsMessage: {
          task_id: this.taskId,
          organization: this.selectedOrganization,
          new_task_name: this.taskName,
          action: actions.cloneTask,
        },
      };
    },

    async getIsTaskExist() {
      const [error, data] = await TaskServices.fetchOrganizationTasks(
        {
          organizations: this.selectedOrganization,
          task_name: this.taskName,
        },
        false
      );
      if (error) {
        console.log('error', error);
        this.toast.error('Something went wrong!');
        return true;
      }

      if (data.results.length > 0)
        this.toast.error(
          'Task with this name already exists in this organization!'
        );

      return data.results.length > 0;
    },
  },
};
</script>
