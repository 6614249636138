<template>
  <a-row :gutter="[8, 8]">
    <a-col span="12">
      <a-select
        id="select-device-filter"
        v-model:value="selectedOrganizations"
        mode="multiple"
        style="width: 100%"
        placeholder="Select Organization(s)"
        :options="organizationOptions"
        :max-tag-count="4"
        :loading="isFetchingOrganization"
        :filter-option="undefined"
      />
    </a-col>
    <a-col :autocave="2">
      <a-button
        id="all-org-select-button"
        @click="handleSelectAllOrganizations"
      >
        {{ isSelectedAllOrgs ? 'Cancel' : 'Select All' }}
      </a-button>
    </a-col>
    <a-col span="2">
      <a-button id="all-tasks-update-btn" type="primary" @click="onUpdate">
        Update
      </a-button>
    </a-col>

    <a-col span="24" class="mt-4">
      <a-table
        :columns="columns"
        :data-source="list"
        :loading="isFetchingTasks || cloneTaskUpdating"
        bordered
        :scroll="{
          y: '55vh',
          x: '1000px',
        }"
        :pagination="pagination"
        class="w-100"
        @change="handleTableChange"
      >
        <template #title>
          <a-typography-title :level="5">
            Tasks: &nbsp;
            <a-tag color="blue">
              {{ tasksCount }}
            </a-tag>
          </a-typography-title>
        </template>
        <template #headerCell="{ title }">
          <span
            :id="'ld-' + title + '-header-cell'"
            class="d-flex justify-content-center"
            >{{ title }}</span
          >
        </template>
        <template
          #customFilterDropdown="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
        >
          <div id="videos-custom-filter-dropdown" style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="handleSearch(selectedKeys, confirm, clearFilters)"
            />
            <a-button
              type="primary"
              size="small"
              class="mr-1"
              style="width: 90px"
              :disabled="!list.length"
              @click="handleSearch(selectedKeys, confirm, clearFilters)"
            >
              <template #icon>
                <SearchOutlined />
              </template>
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
        </template>

        <template #customFilterIcon="{ filtered }">
          <search-outlined
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </template>
        <template #bodyCell="{ column, record, index }">
          <task-record
            :key="index"
            :item="record"
            :column="column"
            :status-options="taskStatusOptions"
            @toggleShowModal="toggleShowModal"
            @setCloneTaskUpdating="handleCloneTaskUpdating"
            @updateList="updateList"
            @runImageSample="runImageSample"
            @setEditTask="handleSetEditTask"
          />
        </template>
      </a-table>
    </a-col>
  </a-row>

  <a-modal
    v-model:visible="show"
    centered
    closable
    title="Clone Task"
    :footer="null"
    destroy-on-close
  >
    <clone-task
      :task-id="task_id"
      :organization-options="organizationOptions"
      @closeModal="closeModal"
    />
  </a-modal>
  <a-modal
    v-model:visible="showEditTask"
    centered
    closable
    :title="titleForTaskCreationCard"
    :footer="null"
    destroy-on-close
    width="60vw"
    @cancel="handleCloseEditTask"
  >
    <a-card>
      <AddNewTask
        v-if="!isFetchingTaskStatus"
        @onCancelEditTask="handleCloseEditTask"
        @onConfirmUpdateTask="handleUpdateTask"
      />
      <div v-else class="d-flex align-items-center justify-content-center">
        <a-spin size="large" />
      </div>
    </a-card>
  </a-modal>
</template>

<script>
import CloneTask from './CloneTask.vue';
import TaskRecord from './TaskRecord.vue';
import AddNewTask from '../../../user-panel/pages/process/Task/AddNewTask.vue';
import DeviceServices from '../../../../services/device';
import TaskServices from '../../../../services/tasks';
import SQSServices from '../../../../services/sqs';
import { mapActions, mapGetters } from 'vuex';
import taskStatus from '../../../../config/training-status-config';
import { taskCreationCardTitle } from 'src/config/task-edit-modes-config.js';
import { actions, queues } from '../../../../config/long-running-task-config';
import { SearchOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    CloneTask,
    TaskRecord,
    AddNewTask,
    SearchOutlined,
  },
  inject: ['toast'],
  setup() {
    return { taskStatus };
  },
  data() {
    return {
      columns: [
        {
          title: 'Id',
          dataIndex: 'id',
          width: '100px',
        },
        {
          title: 'Name',
          dataIndex: 'taskName',
          align: 'center',
          width: '200px',
          customFilterDropdown: true,
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          align: 'center',
          width: '200px',
        },
        {
          title: 'Videos',
          dataIndex: 'numVideos',
          align: 'center',
          width: '100px',
        },
        {
          title: 'Labelled Videos',
          dataIndex: 'numLabelled',
          align: 'center',
          width: '100px',
        },
        {
          title: 'Organization',
          dataIndex: 'Org_name',
          align: 'center',
          width: '200px',
        },
        {
          title: 'Update Time',
          dataIndex: 'status_update_time',
          sorter: true,
          align: 'center',
          width: '200px',
        },
        {
          title: 'Cloned From',
          dataIndex: 'clonedFrom',
          align: 'center',
          width: '200px',
        },
        {
          title: 'Status',
          dataIndex: 'train_status',
          align: 'center',
          width: '200px',
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          align: 'center',
          width: '100px',
        },
      ],
      list: [],
      tasksCount: 0,
      page: 1,
      organizationList: [],
      selectedOrganizations: [],
      task_id: null,
      show: false,
      showEditTask: false,
      isFetchingTasks: false,
      isFetchingOrganization: false,
      cloneTaskUpdating: false,
      searchTaskValue: '',
      clearSearch: () => {},
      toEditRecord: {},
    };
  },

  created() {
    this.getOrganization();
  },

  computed: {
    ...mapGetters([
      'taskDetails',
      'currentEditMode',
      'isFetchingTaskStatus',
      'taskName',
      'taskProcesses',
    ]),
    organizationOptions() {
      return this.organizationList?.map((device) => ({
        value: device.Org_name,
        label: device.Org_name,
      }));
    },
    taskStatusOptions() {
      return Object.values(this.taskStatus).map((el) => ({
        label: el,
        value: el,
      }));
    },
    pagination() {
      return {
        position: ['bottomCenter'],
        showSizeChanger: false,
        current: this.page,
        total: this.tasksCount,
      };
    },
    isSelectedAllOrgs() {
      return (
        this.selectedOrganizations.length === this.organizationOptions.length
      );
    },
    titleForTaskCreationCard() {
      return this.toEditRecord?.task
        ? 'Edit Task ' + taskCreationCardTitle[this.currentEditMode] || ''
        : '';
    },
  },

  watch: {
    showEditTask(value) {
      if (value) this.editTask();
    },
    taskProcesses(_) {
      this.setStepsToIndexMapping();
      this.setsubstepsToStepMapping();
      this.setStepToAttributesMapping();
    },
  },

  methods: {
    ...mapActions([
      'setSelectedTask',
      'setTaskDetail',
      'resetProcessState',
      'setTaskNameAndProcess',
      'setStepsToIndexMapping',
      'setsubstepsToStepMapping',
      'editTask',
      'upsertTask',
      'updateTask',
      'setTasks',
      'setUpdateTaskFromAdmin',
      'setTaskOrganization',
      'setStepsToIndexMapping',
      'setsubstepsToStepMapping',
      'setStepToAttributesMapping',
    ]),
    handleSearch(selectedKeys, confirm, clearFilters) {
      confirm();
      this.searchTaskValue = selectedKeys[0];
      this.clearSearch = () => clearFilters();
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchTaskValue = '';
    },

    handleSelectAllOrganizations() {
      if (this.isSelectedAllOrgs) {
        this.selectedOrganizations = [];
        return;
      }
      const allOrgs = this.organizationOptions.map((org) => org.value);
      this.selectedOrganizations = [...allOrgs];
    },

    async getData(params) {
      this.isFetchingTasks = true;
      this.list = [];
      const query = this.getQueryParams(params);
      const [error, data] = await TaskServices.fetchOrganizationTasks(
        query,
        false
      );
      this.isFetchingTasks = false;
      if (error) {
        console.log({ error });
        return;
      }
      this.setTasks(data.results.map((e) => e.task));
      this.tasksCount = data.count;
      this.list = data.results;
    },

    async getOrganization() {
      this.organizationList = [];
      this.isFetchingOrganization = true;
      const [error, data] = await DeviceServices.getAllOrganizations();
      this.isFetchingOrganization = false;
      if (error) {
        console.log({ error });
        return;
      }
      this.organizationList = data;
    },

    onUpdate() {
      if (this.selectedOrganizations.length === 0)
        return this.toast.info('Organization is not selected');
      this.page = 1;
      this.searchTaskValue = '';
      this.clearSearch();
      this.getData();
    },

    updateList(data) {
      const temp = this.list;
      const index = temp.findIndex((x) => x.id === data.id);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...data.updatedData,
        };
      }
      this.list = temp;
    },

    handleTableChange(pagination, filters, sorter, clearFilters) {
      const { current } = pagination;
      this.page = current;
      this.getData({ sorter: sorter, search: filters['taskName'] });
    },

    getQueryParams(params) {
      const queryParams = {
        organizations: this.selectedOrganizations.join(),
        page: this.page,
      };
      if (params?.search) queryParams['task_name'] = params.search[0];
      if (params?.sorter) {
        const { field, order } = params.sorter;
        queryParams['ordering'] = order
          ? order === 'ascend'
            ? field
            : `-${field}`
          : '';
      }

      return queryParams;
    },

    async runImageSample(record) {
      const { task } = record;
      const payload = {
        queueName: queues.devQueue,
        sqsMessage: {
          task_id: task.id,
          name: task.taskName,
          interval: 100,
          action: actions.imageDetector,
        },
      };

      const [error, data] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log({ error });
        this.toast.error(error);
        return;
      }
      this.toast.success(data.Status);
    },

    toggleShowModal(id) {
      this.task_id = id;
      this.show = !this.show;
    },

    closeModal() {
      this.show = false;
    },

    handleCloneTaskUpdating(status) {
      this.cloneTaskUpdating = status;
    },

    handleSetEditTask(data) {
      this.toEditRecord = data;
      this.handleShowEditTask();
    },

    handleShowEditTask() {
      this.setSelectedTask(this.toEditRecord?.task.id);
      this.setTaskDetail(this.toEditRecord?.task.task_detail);
      this.setTaskNameAndProcess(this.toEditRecord?.task.task_detail);
      this.setStepsToIndexMapping();
      this.setsubstepsToStepMapping();
      this.setUpdateTaskFromAdmin(true);
      this.setTaskOrganization(this.toEditRecord?.task.Organization.Org_name);
      this.showEditTask = true;
    },

    async handleUpdateTask() {
      const taskUpserted = await this.upsertTask();
      if (taskUpserted) {
        await this.updateTask(this.taskDetails);
        this.updateList({
          id: this.toEditRecord.id,
          updatedData: {
            task: {
              ...this.toEditRecord.task,
              task_detail: this.taskDetails,
              taskName: this.taskName,
            },
          },
        });
        this.handleCloseEditTask();
      }
    },

    handleCloseEditTask() {
      this.resetProcessState();
      this.setUpdateTaskFromAdmin(false);
      this.toEditRecord = {};
      this.showEditTask = false;
    },
  },
};
</script>

<style>
table,
tr,
td {
  text-align: left;
  margin-right: auto;
  margin-left: 0px;
}

.ant-popover-content {
  width: 450px;
}
</style>
