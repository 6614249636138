<template>
  <div class="p-4">
    <tasks-list />
  </div>
</template>
<script>
import TasksList from './TaskList.vue';

export default {
  components: {
    TasksList,
  },
};
</script>
